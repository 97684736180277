.main-container {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    color: rgb(252, 246, 233) !important;
}

.coming-soon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    font-family: 'Roboto', Tahoma, Geneva, Verdana, sans-serif;
    background-color: rgb(48, 82, 235);
    padding: 20px;
    color: whitesmoke;
}

BODY {
    background-color: rgb(241, 241, 248);
}
